import { useNavigate } from 'react-router-dom';
import { Button, ButtonProps } from '../../../components/ui';
import { cn } from '@/lib/utils';
import { Slot } from '@radix-ui/react-slot';

type GoBackProps = {
  children: React.ReactNode;
  asChild?: boolean;
  home?: boolean;
};

const DefaultGoBackButton = (props: ButtonProps) => (
  <Button variant='mid' {...props} className={cn('text-foreground', props.className)} />
);

export const GoBack = ({ children, asChild,home }: GoBackProps) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    const previousPage = window.history.state?.key;
   if(home){
     navigate('/');
   } else if (previousPage) {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  const Component = asChild ? Slot : DefaultGoBackButton;

  return <Component onClick={handleGoBack}>{children}</Component>;
};
